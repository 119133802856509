import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ErrorService } from '../../services/error/error.service';
import { GlobalService } from '../../services/global/global.service';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { UserService } from '../../services/user/user.service';
import { WorkerService } from '../../services/worker/worker.service';
import { ImageChecker } from '../../shared/image-checker';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LayoutDialogComponent } from './layout-dialog/layout-dialog.component';
import { LayoutQuickSearchDialogComponent } from './layout-quick-search-dialog/layout-quick-search-dialog.component';
import { layoutLinks, layoutMenu, setIds } from './layout.constants';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
  public permissions: { [key: string]: boolean } = {};
  public online = false;
  public inner_menu_height: number;
  public active_drawer: string = '';
  public last_active_drawer: string = '';
  public pinned_map: any = {};
  public pinned_links_array: any[] = [];
  public operating_system: string = '';

  public router_arrays = {
    education: [],
    groups: [],
    courses: [],
    people: [],
    money: [],
    hr: [],
    consultations: [],
    statistics: [],
    settings: [],
    help: [],
    others: [],
  };

  public getLayoutMenu = layoutMenu;

  private last_scroll_position = 0;
  private is_dialog_open: boolean = false;

  @HostListener('scroll', ['$event'])
  public onScroll(event: Event, type: string): void {
    const element = event.target as Element;
    const current_scroll_position = element.scrollTop;
    const max_scroll = element.scrollHeight - element.clientHeight;

    const scrolled_block =
      type === 'links'
        ? this.scrolled_pinned_block
        : this.scrolled_actions_block;

    this.scrolledBlock(scrolled_block, current_scroll_position, max_scroll);
  }

  @ViewChild('scrolled_pinned_block', { read: ElementRef })
  public scrolled_pinned_block: ElementRef<HTMLElement>;

  @ViewChild('scrolled_actions_block', { read: ElementRef })
  public scrolled_actions_block: ElementRef<HTMLElement>;

  @ViewChild('pinned_links', { read: ElementRef })
  public pinned_links: ElementRef<HTMLElement>;

  @ViewChild('bottom_menu', { read: ElementRef })
  public bottom_menu: ElementRef<HTMLElement>;

  @ViewChild('sub_drawer') sub_drawer: MatDrawer;

  private _resize_sub$: Subscription;

  get user(): any {
    return this.globalService.user;
  }

  set user(value) {
    this.globalService.user = value;
  }

  get school(): any {
    return this.globalService.school;
  }

  set school(value) {
    this.globalService.school = value;
  }

  get page_title(): string {
    return this.globalService.page_title;
  }

  set page_title(value) {
    this.globalService.page_title = value;
  }

  get opened_main_menu(): boolean {
    return this.globalService.sidebar_opened;
  }

  set opened_main_menu(value) {
    this.globalService.sidebar_opened = value;
  }

  get url_support(): boolean {
    return this.globalService.url_support;
  }

  get url_support_chat(): boolean {
    return this.globalService.url_support_chat;
  }

  get load(): boolean {
    return this.globalService.load;
  }

  get errors(): any[] {
    return this.errorService.stored_errors;
  }

  get birthdayUser(): any[] {
    return this.globalService.birthdayUsers[
      Math.floor(Math.random() * this.globalService.birthdayUsers.length)
    ];
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent): void {
    const is_mac_os = this.operating_system === 'macos';
    const is_shortcut_pressed = is_mac_os ? event.metaKey : event.ctrlKey;

    if (event.key === '/' && is_shortcut_pressed && !this.is_dialog_open) {
      this.openQuickSearchDialog();

      this.is_dialog_open = true;
    }
  }

  constructor(
    private globalService: GlobalService,
    public imageChecker: ImageChecker,
    private userService: UserService,
    private router: Router,
    private errorService: ErrorService,
    private permissionService: PermissionsService,
    private cd: ChangeDetectorRef,
    private workerService: WorkerService,
    private renderer: Renderer2,
    public dialog: MatDialog,
  ) {
    this.page_title = 'Time Table 2.0';

    this.checkOperatingSystem();

    setIds(this.user._id, this.school._id);

    this.permissions.groups = this.permissionService.checkOneOfAll([
      { name: 'sprints' },
      { name: 'groups_students', schools: [this.school._id] },
      { name: 'groups', schools: [this.school._id] },
      { name: 'keycalls' },
      { name: 'lms_chat' },
      { name: 'polls' },
    ]);

    this.permissions.courses = this.permissionService.checkOneOfAll([
      { name: 'courses' },
      { name: 'programme' },
      { name: 'recommendations' },
      { name: 'course_events' },
      { name: 'video-courses' },
      { name: 'mailings' },
      { name: 'code_tasks_management' },
      { name: 'quiz' },
    ]);

    this.permissions.budget = this.permissionService.checkOneOfAll([
      { name: 'groups_budget' },
      { name: 'categories_budget' },
      { name: 'salaries_budget' },
    ]);

    this.permissions.menu_add = this.permissionService.checkOneOfAll([
      { name: 'student_add' },
      { name: 'groups_add_edit', schools: [this.school._id] },
      { name: 'courses_add_edit' },
      { name: 'users_add' },
      { name: 'video-courses' },
      { name: 'classes' },
      { name: 'school_add' },
      { name: 'fops_add' },
      { name: 'company_control' },
    ]);

    this.permissions.hr = this.permissionService.checkOneOfAll([
      { name: 'interviews' },
      { name: 'student_cv', schools: [this.school._id] },
      { name: 'management_skills' },
      { name: 'company_view' },
      { name: 'vacancy' },
    ]);

    this.permissions.help = this.permissionService.checkOneOfAll([
      { name: 'help_page' },
      { name: 'coach_instruction' },
    ]);

    this.permissions.others = this.permissionService.checkPermission({
      name: 'video',
      schools: [this.school._id],
    });

    this.permissions.search = this.permissionService.checkPermission({
      name: 'search',
    });

    this._resize_sub$ = fromEvent(window, 'resize').subscribe(() => {
      this.calcInnerMenuHeight();
    });

    this.getRouterArrays();
  }

  public ngOnInit(): void {
    this.workerService.createWorker();

    this.getUserNavigation();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeDrawer();
      }
    });
  }

  public ngAfterViewChecked(): void {
    this.calcInnerMenuHeight();

    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.workerService.destroyWorker();
    this._resize_sub$.unsubscribe();
  }

  public calcInnerMenuHeight(): void {
    const pinned_links_length = this.pinned_links_array?.length;
    const pinned_links_height = this.pinned_links?.nativeElement.clientHeight;
    const bottom_menu_height = this.bottom_menu?.nativeElement.clientHeight;

    const main_addendum = window.innerHeight - bottom_menu_height - 115;

    this.inner_menu_height = pinned_links_length
      ? main_addendum - pinned_links_height
      : main_addendum;
  }

  public logout(): void {
    this.userService.logout().subscribe(({ success }) => {
      if (success) {
        this.workerService.closeSocket();

        this.router.navigate(['/', 'login']);
      }
    });
  }

  public closeErrors(): void {
    this.errorService.showedErrors();
  }

  public clearErrors(): void {
    this.errorService.clearErrors();
    this.cd.detectChanges();
  }

  public getUserNavigation(): void {
    this.userService.getUserNavigation().subscribe(({ success, response }) => {
      if (success) {
        this.pinned_links_array = response;

        this.pinItemFromMenu();
      }
    });
  }

  public pinItem(data: any): void {
    this.userService.addUserNavigationItem(data).subscribe(({ success }) => {
      if (success) {
        this.getUserNavigation();
        this.calcInnerMenuHeight();
      }
    });
  }

  public pinItemFromMenu(): void {
    this.pinned_map = (this.pinned_links_array || []).reduce((acc, item) => {
      acc[item.title] = item;
      return acc;
    }, {});
  }

  public toggleDrawer(drawer_content: string): void {
    if (this.active_drawer !== drawer_content) {
      this.active_drawer = drawer_content;

      this.sub_drawer.open();
      this.last_active_drawer = 'open';
    } else {
      if (this.last_active_drawer === 'close') {
        this.sub_drawer.open();

        this.last_active_drawer = 'open';
      } else {
        this.sub_drawer.close();

        this.last_active_drawer = 'close';
      }
    }
  }

  public isActiveRoute(routers: string[]): boolean {
    return routers.some((router) => this.router.url.includes(router));
  }

  public setActiveDrawer(drawer_content: string): void {
    this.active_drawer = drawer_content;
  }

  public closeDrawer(): void {
    this.sub_drawer.close();
    this.last_active_drawer = 'close';
  }

  public openQuickAccessDialog(): void {
    this.dialog
      .open(LayoutDialogComponent, {
        panelClass: 'dialog-layout',
        width: '450px',
        data: this.pinned_links_array,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getUserNavigation();
          this.calcInnerMenuHeight();
        }
      });
  }

  public openQuickSearchDialog(): void {
    this.dialog
      .open(LayoutQuickSearchDialogComponent, {
        panelClass: 'dialog-layout',
        width: '450px',
        autoFocus: false,
        position: {
          top: '110px',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        this.is_dialog_open = false;

        if (result) {
          this.getUserNavigation();
          this.calcInnerMenuHeight();
        }
      });
  }

  private getRouterArrays(): void {
    const _router_arrays = layoutLinks();

    _router_arrays.forEach((item) => {
      if (item.active_router) {
        this.router_arrays[item.section].push(item.active_router);
      }
    });
  }

  private scrolledBlock(
    scrolled_block,
    current_scroll_position,
    max_scroll,
  ): void {
    const percentage_scrolled = (current_scroll_position / max_scroll) * 100;

    const class_map = {
      'layout__menu-pinned--long-top': current_scroll_position === 0,
      'layout__menu-pinned--long-bottom': percentage_scrolled >= 95,
      'layout__menu-pinned--long-add-top':
        percentage_scrolled > 0 && percentage_scrolled < 95,
    };

    this.applyClasses(scrolled_block.nativeElement, class_map);
    this.last_scroll_position = current_scroll_position;
  }

  private applyClasses(element, classMap): void {
    for (const [class_name, condition] of Object.entries(classMap)) {
      if (condition) {
        this.renderer.addClass(element, class_name);
      } else if (class_name !== 'layout__menu-pinned--long-add-top') {
        this.renderer.removeClass(element, class_name);
      }
    }
  }

  private checkOperatingSystem() {
    const user_agent = navigator.userAgent.toLowerCase();
    this.operating_system = user_agent.includes('mac') ? 'macos' : 'others';
  }
}
